<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import {
  FETCH_SINGLE_COURSE_ACTION,
  GET_CURRENT_COURSE_GETTER,
  UPDATE_MODULE_STATUS_ACTION,
  UPDATE_LESSON_STATUS_ACTION,
} from "@/store/modules/USER/constants";
import Accordion from "@/components/ui/baseComponents/accordion.vue";
import UserCourseLessons from "@/components/layout/generalUser/User/UserCourseLessons.vue";

const route = useRoute();
const store = useStore();

store.dispatch(`User/${FETCH_SINGLE_COURSE_ACTION}`, {
  course: route.params.id,
});

const singleCourse = computed(() => {
  return store.getters[`User/${GET_CURRENT_COURSE_GETTER}`];
});

const courseType = [
  {
    title: "Digital",
    value: "digital",
  },
  {
    title: "Physical",
    value: "physical",
  },
];

const moduleCompleted = (data) => {
  store.dispatch(`User/${UPDATE_MODULE_STATUS_ACTION}`, data);
};
const lesonConpleted = (data) => {
  console.log(data);
  store.dispatch(`User/${UPDATE_LESSON_STATUS_ACTION}`, data);
};
</script>
<template>
  <div class="w-full h-full flex flex-col p-5">
    <div class="bg-gray-200 rounded-md p-5">
      <div class="flex">
        <div class="flex-1">
          <p class="text-2xl font-medium">
            {{ singleCourse?.name || "Training Course Name" }}
          </p>
          <p>
            {{ singleCourse?.description || "Training Course Description" }}
          </p>
          <div class="flex items-center">
            Training Type:
            <div
              class="form-check flex items-center"
              v-for="item in courseType"
              :key="item.value"
            >
              <input
                class="float-left mx-2 cursor-pointer"
                type="radio"
                name="flexRadioDefault"
                :checked="singleCourse?.type === item.value"
                :value="item.value"
                :id="item.value"
                disabled
              />
              <label
                class="form-check-label inline-block text-gray-800"
                :for="item.value"
              >
                {{ item.title }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5">
      <template v-if="singleCourse">
        <accordion
          v-for="(singleModule, index) in singleCourse.modules"
          :key="index"
          :questionsData="singleModule.lessons"
          :title="singleModule.name"
          :accordionText="'lessons'"
          :classes="'my-2'"
        >
          <UserCourseLessons
            :moduleData="singleModule"
            @moduleCompleted="moduleCompleted"
            @lesonConpleted="lesonConpleted"
          />
        </accordion>
      </template>
    </div>
  </div>
</template>