<script setup>
import { defineProps, ref, defineEmits } from "vue";
import ToggleComponent from "@/components/ui/baseComponents/ToggleComponent.vue";
import UserCourseSingleLesson from "./UserCourseSingleLesson.vue";
import UserModuleExamInformation from "./UserModuleExamInformation.vue";
import Swal from "sweetalert2";

const emits = defineEmits(["moduleCompleted", "lesonConpleted"]);
const props = defineProps({
  moduleData: {
    type: Object,
    default: () => {},
  },
});
const file = ref();
const moduleCompleted = ref(
  props.moduleData?.module_progress.length
    ? Boolean(props.moduleData?.module_progress?.[0]?.is_completed)
    : false
);

const selectFile = () => {
  file.value.click();

  // Do something with chosen file
};
const uploadFiles = (e) => {
  const fd = new FormData();
  fd.append("completion_evidence", e.target.files[0]);
  fd.append("completed_at", new Date().toLocaleDateString("en-GB"));
  fd.append("is_completed", moduleCompleted.value ? 1 : 0);
  fd.append("module", props.moduleData.id);
  emits("moduleCompleted", fd);
  Swal.fire({
    title: "Done",
    text: "successfully Saved The Progress",
    icon: "success",
  });
};
</script>
<template>
  <div class="flex justify-end">
    <div class="w-80 bg-gray-200 rounded-md p-5 m-5">
      <div class="flex items-center justify-between">
        <span>Module Completed?</span>
        <toggle-component
          label=""
          labelID="moduledone"
          checkedColour="bg-orange"
          :disabled="
            Boolean(
              moduleCompleted &&
                props.moduleData?.module_progress?.[0]?.completion_evidence
            )
          "
          v-model:value="moduleCompleted"
        />
      </div>
      <template v-if="moduleCompleted">
        <div
          v-if="!props.moduleData?.module_progress?.[0]?.completion_evidence"
          class="uploadFile bg-white rounded-md px-3 py-2 text-center mt-3"
        >
          <input @change="uploadFiles" class="hidden" ref="file" type="file" />
          <div
            @click="selectFile"
            class="justify-center flex items-center cursor-pointer"
          >
            Upload Evidence
            <span class="material-icons-outlined text-teal ml-3"> backup </span>
          </div>
        </div>
        <div v-else class="flex justify-between mt-4">
          <span class="text-gray-500">Completed Evidance</span>
          <span class="mr-4 cursor-pointer text-teal">
            <a
              :href="
                props.moduleData?.module_progress?.[0]?.completion_evidence
              "
            >
              <span class="material-icons-outlined font-medium">
                attachment
              </span>
            </a>
          </span>
        </div>
      </template>
    </div>
  </div>
  <div
    class="flex gap-y-3"
    v-for="(lesson, index) in props.moduleData?.lessons"
    :key="index"
  >
    <UserCourseSingleLesson
      :lesson="lesson"
      @lesonConpleted="
        (data) => {
          emits('lesonConpleted', data);
        }
      "
    />
  </div>
  <UserModuleExamInformation
    v-if="Boolean(props.moduleData.is_exam_required)"
  />
</template>