<script setup>
import { defineProps, ref, toRefs, defineEmits } from "vue";
import ToggleComponent from "@/components/ui/baseComponents/ToggleComponent.vue";
import Swal from "sweetalert2";

const emits = defineEmits(["lesonConpleted"]);
const props = defineProps({
  lesson: {
    type: Object,
    default: () => {},
  },
});
const { lesson } = toRefs(props);
const lessonCompleted = ref(
  Boolean(props.lesson?.lesson_progress?.[0]?.is_completed) || false
);

const file = ref();

const selectFile = () => {
  file.value.click();

  // Do something with chosen file
};
const uploadFiles = (e) => {
  const fd = new FormData();
  fd.append("completion_evidence", e.target.files[0]);
  fd.append("completed_at", new Date().toLocaleDateString("en-GB"));
  fd.append("is_completed", lessonCompleted.value ? 1 : 0);
  fd.append("lesson", props.lesson.id);
  emits("lesonConpleted", fd);
  Swal.fire({
    title: "Done",
    text: "successfully Saved The Progress",
    icon: "success",
  });
};
</script>
<template>
  <div class="flex-grow">
    <div class="bg-gray-200 rounded-md p-5 m-5">
      <p>{{ lesson.name }}</p>
      <div class="flex mt-5 gap-5">
        <div class="flex-col flex">
          <p class="bg-white rounded-md px-4 py-2 my-2">
            <span class="font-medium">Start Date: </span>{{ lesson.start_date }}
          </p>
          <p class="bg-white rounded-md px-4 py-2 my-2">
            <span class="font-medium">End Date: </span>{{ lesson.due_date }}
          </p>
          <p class="bg-white rounded-md px-4 py-2 my-2">
            <span class="font-medium">Time Taken: </span>
          </p>
        </div>
        <div class="flex-col flex flex-1">
          <div class="bg-white rounded-md px-4 py-2 my-2 flex-1">
            {{ lesson.description }}
          </div>
          <div class="bg-white rounded-md px-4 py-2 my-2">
            {{ lesson.url }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-80 m-5">
    <div class="bg-gray-200 rounded-md p-5">
      <div class="flex items-center justify-between">
        <span>Lesson Completed?</span>
        <toggle-component
          label=""
          labelID="lessonDone"
          checkedColour="bg-orange"
          :disabled="
            Boolean(
              lessonCompleted &&
                props.lesson?.lesson_progress?.[0]?.completion_evidence
            )
          "
          v-model:value="lessonCompleted"
        />
      </div>

      <template v-if="lessonCompleted">
        <div
          v-if="!props.lesson?.lesson_progress?.[0]?.completion_evidence"
          class="uploadFile bg-white rounded-md px-3 py-2 text-center mt-3"
        >
          <input @change="uploadFiles" class="hidden" ref="file" type="file" />
          <div
            @click="selectFile"
            class="justify-center flex items-center cursor-pointer"
          >
            Upload Evidence
            <span class="material-icons-outlined text-teal ml-3"> backup </span>
          </div>
        </div>
        <div v-else class="flex justify-between mt-4">
          <span class="text-gray-500">Completed Evidance</span>
          <span class="mr-4 cursor-pointer text-teal">
            <a :href="props.lesson?.lesson_progress?.[0]?.completion_evidence">
              <span class="material-icons-outlined font-medium">
                attachment
              </span>
            </a>
          </span>
        </div>
      </template>
    </div>
  </div>
</template>